<template>
  <div class="custom-search row">
    <b-col
      md="4"
    >
      <b-form-group label="User">
        <v-select
          v-model="form.teamId"
          :reduce="(item) => item.uuid"
          :options="teams"
          label="name"
          placeholder="Select Team"
          @input="$emit('input', form)"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
    >
      <b-form-group label="Timezone">
        <v-select
          v-model="form.time_zone"
          :options="timeZones"
          :reduce="item => item.value"
          label="title"
          :clearable="false"
          @input="updateTimezone"
        >
          <template #option="{ title }">
            <span> {{ title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </b-col>
    <b-col
      md="4"
    >
      <div class="row">
        <div class="col-6">
          <b-form-group label="Start Date">
            <div class="d-flex align-items-center">
              <b-form-datepicker
                v-model="form.dateRange.startDate"
                :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
                :max="form.dateRange.endDate"
                @input="$emit('input', form)"
              />
            </div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="End Date">
            <div class="d-flex align-items-center">
              <b-form-datepicker
                v-model="form.dateRange.endDate"
                :min="form.dateRange.startDate"
                :max="TODAY()"
                menu-class="mr-3"
                :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
                @input="$emit('input', form)"
              />
            </div>
          </b-form-group>
        </div>
      </div>
    </b-col>
    <!-- <div class="col-9 pull-right">
      <label class="mr-1">Date</label>
      <b-form-group>
        <date-range-picker
          ref="picker"
          v-model="form.dateRange"
          :locale-data="{format: 'yyyy-mm-dd' }"
        >
          <template
            v-slot:input="picker"
            style="min-width: 350px;"
          >
            {{ FORMAT_DATE(picker.startDate, 'MMMM Do YYYY') }} - {{ FORMAT_DATE(picker.endDate,'MMMM Do YYYY') }}
          </template>
        </date-range-picker>
      </b-form-group>
    </div> -->
  </div>
</template>
<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    vSelect,
    BFormDatepicker,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        teamId: this.value.teamId,
        dateRange: {
          startDate: this.value.dateRange.startDate,
          endDate: this.value.dateRange.endDate,
        },
        time_zone: this.value.time_zone,
      },
      teams: [],
      timeZones: [],
    }
  },
  mounted() {
    useApollo.team.getTeamDropdown({
      uuid: this.$store.state.project.selectedProject,
      page: 1,
      first: -1,
    }).then(response => {
      this.teams = response.data.project.teams.data
    })
    useApollo.company.getMyCompanyTimeZone({ companyUid: this.$store.state.project.selectedCompany }).then(response => {
      const company = response.data.me.companies.data[0]
      const defaultTimezone = company.timezoneOffset
      const reportTimezone = company.timezones.data.filter(timezone => timezone.name !== defaultTimezone.name)
      this.timeZones = [
        {
          title: defaultTimezone.displayName,
          value: defaultTimezone.name,
        },
        ...reportTimezone.map(rt => ({ title: rt.displayName, value: rt.name })),
      ]
    }).catch(error => {
      console.log(error)
    })
  },
  methods: {
    updateTimezone(newValue) {
      this.$store.dispatch('project/STORE_SELECTED_TIMEZONE', newValue).then(() => {
        this.$emit('input', this.form)
      })
    },
  },
}
</script>

<template>
  <b-card
    :border-variant="variant"
    body-class="p-0 p-md-1"
  >
    <b-card-header>
      <h4 class="mb-0">
        {{ title }}
      </h4>
      <!-- <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text> -->
    </b-card-header>

    <!-- apex chart -->
    <b-row>
      <b-col
        v-for="user of rankedTeam"
        :key="user.name"
        cols="6"
        md="3"
        class="text-center mt-1"
      >
        <vue-apex-charts
          type="radialBar"
          height="150"
          :options="chartOptions"
          :series="[user.time || 0]"
        />
        <h4
          class="text-nowrap text-truncate small"
          :title="user.name"
        >
          <b-avatar
            :src="getAvatar(user.avatar)"
            size="30"
            badge
            :badge-variant="user.isOnline ? 'success' : 'secondary'"
            :text="user.name.charAt(0)"
            class="mr-1"
            variant="light-primary"
          />
          <span class="p-0 p-md-1">{{ user.name }}</span>
        </h4>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardText, BAvatar,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import semiChartOptions from '@/charts/semi-chart'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BAvatar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: semiChartOptions({ paddingButton: 20, variant: this.variant, fontSize: '1.5rem' }),
    }
  },
  computed: {
    rankedTeam() {
      const { items } = this
      items.sort((a, b) => b.time - a.time)
      return items
    },
  },
}
</script>
